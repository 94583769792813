<template>
    <div v-if="getSiderbar" class="side-nav">
        <ul class="navbar-nav custom-side-nav-background sidebar sidebar-dark accordion" id="accordionSidebar">
            <li class="nav-item">
                <span class="nav-link custom-nav-link" @click="navigateAccountBalance()">
                    <b-row>
                        <b-col lg="2" sm="12">
                            <font-awesome-icon class="custom-side-nav-icon" icon="fa-solid fa-house" />
                        </b-col>
                        <b-col lg="10" sm="12">
                            <span class="custom-nav-item custom-nav-item-mobile">Home</span>
                        </b-col>
                    </b-row> 
                </span>
            </li>

            <li class="nav-item">
                <span class="nav-link custom-nav-link" v-b-toggle.collapse-1>
                    <b-row>
                        <b-col lg="2" sm="12">
                            <font-awesome-icon class="custom-side-nav-icon" icon="fa-solid fa-user" />
                        </b-col>
                        <b-col lg="8" sm="12">
                            <span class="custom-nav-item">Your Accounts</span>
                        </b-col>
                        <b-col lg="2" sm="12">
                            <font-awesome-icon v-if="!accountsCollapse" icon="fa-solid fa-angle-right" class="custom-side-nav-icon  custom-angle-icon" />
                            <font-awesome-icon v-if="accountsCollapse" icon="fa-solid fa-angle-down" class="custom-side-nav-icon  custom-angle-icon" />
                        </b-col>
                    </b-row> 
                </span>
                <b-collapse id="collapse-1" class="collapse mt-2" v-model="accountsCollapse">
                    <b-card class="collapse-inner rounded p-0">
                        <span class="custom-nav-link collapse-item" @click="navigateAccount()"><span class="custom-hyphen">-</span> Member Info</span>
                        <span class="custom-nav-link collapse-item" @click="navigateCurrentAccounts()"><span class="custom-hyphen">-</span> Current Accounts</span>
                        <span class="custom-nav-link collapse-item" @click="navigateLoans()"><span class="custom-hyphen">-</span> Loan Accounts</span>
                        <span class="custom-nav-link collapse-item" @click="navigateInvesta()"><span class="custom-hyphen">-</span> Investa Accounts</span>
                        <span class="custom-nav-link collapse-item" @click="navigateShares()"><span class="custom-hyphen">-</span> Shares Accounts</span>
                    </b-card>
                </b-collapse>
            </li>

            <li class="nav-item">
                <span class="nav-link custom-nav-link" v-b-toggle.collapse-2>
                    <b-row>
                        <b-col lg="2" sm="12">
                            <font-awesome-icon class="custom-side-nav-icon" icon="fa-solid fa-sack-dollar" />
                        </b-col>
                        <b-col lg="8" sm="12">
                            <span class="custom-nav-item">Loans</span>
                        </b-col>
                        <b-col lg="2" sm="12">
                            <font-awesome-icon v-if="!loansCollapse" icon="fa-solid fa-angle-right" class="custom-side-nav-icon custom-angle-icon" />
                            <font-awesome-icon v-if="loansCollapse" icon="fa-solid fa-angle-down" class="custom-side-nav-icon  custom-angle-icon" />
                        </b-col>
                    </b-row>                                      
                </span>
                <b-collapse id="collapse-2" class="collapse mt-2" v-model="loansCollapse">
                    <b-card class="collapse-inner rounded">
                        <span class="custom-nav-link collapse-item" @click="navigateLoanCalculator()"><span class="custom-hyphen">-</span> Loan Calculator</span>
                        <span class="custom-nav-link collapse-item" @click="navigateLoanApplications()"><span class="custom-hyphen">-</span> Loan Applications</span>
                    </b-card>
                </b-collapse>
            </li>

            <li class="nav-item">
                <span class="nav-link custom-nav-link" v-b-toggle.collapse-3>
                    <b-row>
                        <b-col lg="2" sm="12">
                            <font-awesome-icon class="custom-side-nav-icon" icon="fa-solid fa-file" />
                        </b-col>
                        <b-col lg="8" sm="12">
                            <span class="custom-nav-item">Guarantorship</span>
                        </b-col>
                        <b-col lg="2" sm="12">
                            <font-awesome-icon v-if="!guarantorCollapse" icon="fa-solid fa-angle-right" class="custom-side-nav-icon custom-angle-icon" />
                            <font-awesome-icon v-if="guarantorCollapse" icon="fa-solid fa-angle-down" class="custom-side-nav-icon  custom-angle-icon" />
                        </b-col>
                    </b-row> 
                </span>
                <b-collapse id="collapse-3" class="collapse mt-2" v-model="guarantorCollapse">
                    <b-card class="collapse-inner rounded">
                        <span class="custom-nav-link collapse-item" @click="navigateLoanGuarantors()"><span class="custom-hyphen">-</span> Loan Guarantors</span>
                        <span class="custom-nav-link collapse-item" @click="navigateLoanGuarantees()"><span class="custom-hyphen">-</span> Loan Guarantees</span>
                    </b-card>
                </b-collapse>
            </li>

            <li class="nav-item" @click="navigateCustomerCare()">
                <span class="custom-nav-link nav-link">
                    <b-row>
                        <b-col lg="2" sm="12">
                            <font-awesome-icon class="custom-side-nav-icon" icon="fa-solid fa-headset" />
                        </b-col>
                        <b-col lg="10" sm="12">
                            <span class="custom-nav-item">Customer Care</span>
                        </b-col>
                    </b-row> 
                </span>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: 'SideNav',
    computed: {
    ...mapGetters(['getSiderbar'])
    },

    data() {
      return {
        accountsCollapse: false,
        loansCollapse: false,
        guarantorCollapse: false
      }
    },

    methods: {
        navigateAccount() {
            this.$router.push("/account")
        },

        navigateCurrentAccounts() {
            this.$router.push("/account/current")
        },

        navigateLoans() {
            this.$router.push("/account/loan")
        },

        navigateInvesta() {
            this.$router.push("/account/investa")
        },

        navigateShares() {
            this.$router.push("/account/shares")
        },

        navigateAccountBalance() {
            this.$router.push("/account/balance")
        },

        navigateLoanCalculator() {
            this.$router.push("/loan-calculator")
        },

        navigateLoanApplications() {
            this.$router.push("/loan-applications")
        },

        navigateAccountStatement() {
            this.$router.push("/account/statement")
        },

        navigateCustomerCare() {
            this.$router.push("/customer-care")
        },

        navigateLoanGuarantors() {
            this.$router.push("/loan-guarantors")
        },

        navigateLoanGuarantees() {
            this.$router.push("/loan-guarantees")
        },

        navigateHome() {
            this.$router.push("/")
        }
    }
}
</script>
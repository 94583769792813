import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

import HomeView from '../views/HomeView.vue'

import AccountView from '../views/Account/AccountView.vue'
import AccountBalanceView from '../views/Account/AccountBalanceView'
import SavingView from '../views/Account/SavingView'
import LoanView from '../views/Account/LoanView'
import FixedDepositView from '../views/Account/FixedDepositView'
import SharesView from '../views/Account/SharesView'

import LoanCalculatorView from '../views/Loan/LoanCalculatorView2'
import LoanApplicationsView from '../views/Loan/LoanApplicationsView'
import LoanGuarantorsView from '../views/Loan/LoanGuarantorsView'
import LoanGuaranteesView from '../views/Loan/LoanGuaranteesView'

import CustomerCareView from '../views/CustomerCareView'

import LogoutView from '../views/Auth/LogoutView'
import AuthChooseView from '../views/Auth/AuthChooseView'
import RegisterView from '../views/Auth/RegisterView'
import SetPasswordView from '../views/Auth/SetPasswordView'
import OTPVerificationView from '../views/Auth/OTPVerificationView'
import SecurityQuestionsView from '../views/Auth/SecurityQuestionsView'
import LoginView from '../views/Auth/LoginView'
import ChangePasswordView from '../views/Auth/ChangePasswordView'
import ForgotPasswordView from '../views/Auth/ForgotPasswordView'

import TermsView from '../views/TermsView'

Vue.use(VueRouter)

function guard(to, from, next) {
	if(localStorage.getItem("userDetails")) {
		next()
	}
	else {
		next({ name: 'login' })
	}
}

function saveRoute(to, from, next) {
  store.commit('setBeforeTerms', from)
  next()
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/auth-choose',
    name: 'auth-choose',
    component: AuthChooseView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView 
  },
  {
    path: '/set-password',
    name: 'set-password',
    component: SetPasswordView 
  },
  {
    path: '/otp-verification',
    name: 'otp-verification',
    component: OTPVerificationView 
  },
  {
    path: '/security-questions',
    name: 'security-questions',
    component: SecurityQuestionsView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: ChangePasswordView,
    beforeEnter : guard,
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPasswordView,
  },

  {
    path: '/account',
    name: 'account',
    component: AccountView,
    beforeEnter : guard,
  },
  {
    path: '/account/balance',
    name: 'account-balance',
    component: AccountBalanceView,
    beforeEnter : guard,
  },
  {
    path: '/account/current',
    name: 'account-current',
    component: SavingView,
    beforeEnter : guard,
  },
  {
    path: '/account/loan',
    name: 'account-loan',
    component: LoanView,
    beforeEnter : guard,
  },
  {
    path: '/account/investa',
    name: 'account-investa',
    component: FixedDepositView,
    beforeEnter : guard,
  },
  {
    path: '/account/shares',
    name: 'account-shares',
    component: SharesView,
    beforeEnter : guard,
  },
  {
    path: '/loan-calculator',
    name: 'loan-calculator',
    component: LoanCalculatorView,
    beforeEnter : guard,
  },
  {
    path: '/loan-applications',
    name: 'loan-applications',
    component: LoanApplicationsView,
    beforeEnter : guard,
  },
  {
    path: '/customer-care',
    name: 'customer-care',
    component: CustomerCareView,
    beforeEnter : guard,
  },  
  {
    path: '/logout',
    name: 'logout',
    component: LogoutView,
    beforeEnter : guard,
  }, 
  {
    path: '/loan-guarantors',
    name: 'loan-guarantors',
    component: LoanGuarantorsView,
    beforeEnter : guard,
  },
  {
    path: '/loan-guarantees',
    name: 'loan-guarantees',
    component: LoanGuaranteesView,
    beforeEnter : guard,
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsView,
    beforeEnter : saveRoute,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

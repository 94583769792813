<template>
    <div class="account">

        <TopNav />

        <div id="wrapper">
            <SideNav />

            <div id="content-wrapper" class="d-flex flex-column">
                <div id="content">
                    <div class="row">
                        <div class="col-12">
                            <b-card>
                                <p class="custom-account-statement-title">
                                    Loan Calculator
                                </p>
                                <div class="row mb-2">
                                    <div class="col-lg-3">
                                        <label><strong>Loan Product</strong></label><br />
                                        <b-form-select class="custom-account-statement-filter-input"  @change="changeLoanProduct($event)">
                                            <option :value="null" disabled>-- Please select an option --</option>
                                            <option v-for="product in products" :value="product">
                                                {{ product.description }}
                                            </option>
                                        </b-form-select>
                                    </div>

                                    <div class="col-lg-3">
                                        <label><strong>Loan Period &nbsp;</strong><small v-if="selectedPeriod != 0">{{ selectedPeriod }} Month(s)</small></label><br />
                                        <b-form-input v-model="selectedPeriod" type="range" min="0" :max="maxLoanTerm" class="custom-account-statement-filter-input"></b-form-input>
                                    </div>   

                                    <div class="col-lg-3">
                                        <label><strong>Loan amount</strong></label><br />
                                        <input type="text" v-model="loanAmount" class="custom-account-statement-filter-input form-control" @keyup="formatNumber()">
                                    </div>                              

                                    <div class="col-lg-2">
                                        <label></label><br />
                                        <b-button-group>
                                            <b-button class="custom-auth-signin mt-1" @click="calculateLoan()"
                                                :disabled="btnDisabled">
                                                <b-spinner class="custom-spinner" label="Spinning" v-if="btnIcon"
                                                    small></b-spinner>
                                                <span v-if="!btnIcon">CALCULATE</span>
                                            </b-button>
                                            <b-button class="custom-auth-signin custom-btn-group mt-1" @click="mountComponent()" :disabled="exportBtn">
                                                <b-spinner class="custom-spinner" label="Spinning" v-if="exportBtnIcon"
                                                    small></b-spinner>
                                                <span v-if="!exportBtnIcon">EXPORT</span>
                                            </b-button>
                                        </b-button-group>
                                    </div>


                                </div>
                                <small><i>**Charges Apply</i></small>
                                <b-table striped hover bordered :fields="fields" :items="installments" responsive
                                    :busy="isBusy" show-empty>
                                    <template #cell(installmentNumber)="row">
                                        <span>{{ row.item.instNo }}</span>
                                    </template>
                                    <template #cell(installmentDate)="row">
                                        <span>{{ row.item.instDate }}</span>
                                    </template>
                                    <template #cell(principalBalance)="row">
                                        <span>{{ row.item.prnBalance.toLocaleString() }}</span>
                                    </template>
                                    <template #cell(installmentAmount)="row">
                                        <span>{{ row.item.instAmount.toLocaleString() }}</span>
                                    </template>
                                    <template #cell(principalAmount)="row">
                                        <span>{{ row.item.prnAmount.toLocaleString() }}</span>
                                    </template>
                                    <template #cell(interest)="row">
                                        <span>{{ row.item.intAmount.toLocaleString() }}</span>
                                    </template>

                                    <template #table-busy>
                                        <div class="text-center text-danger my-2">
                                            <b-spinner class="align-middle"></b-spinner>
                                            <strong>Loading...</strong>
                                        </div>
                                    </template>

                                    <template #empty="scope">
                                        <p>No data</p>
                                    </template>
                                </b-table>
                            </b-card>
                        </div>
                    </div>
                </div>
            </div>

            <template v-if="exportComponent">
                <Export :data="componentData" :installments="installments" v-on:unmount-export-calculator-component="unmountComponent"></Export>
            </template>
        </div>
        <Footer />
    </div>
</template>

<script>
import api from "../../apis/api";
import { mapGetters } from 'vuex'
import TopNav from '@/components/TopNav.vue'
import SideNav from '@/components/SideNav.vue'
import Footer from '@/components/Footer.vue'
import Export from '@/components/ExportCalculator.vue'

export default {
    name: 'LoanCalculatorView2',
    components: {
        SideNav,
        TopNav,
        Footer,
        Export
    },
    data() {
        return {
            fields: [
                "installmentNumber",
                "installmentDate",
                "principalBalance",
                "installmentAmount",
                "principalAmount",
                "interest"
            ],
            selectedPeriod: 0,
            maxLoanTerm: 0,
            loanAmount: null,
            products: [],
            selectedProduct: null,            
            installments: null,
            accountNumbers: [],
            selectedAccount: null,
            date: null,
            btnDisabled: false,
            btnIcon: false,
            isBusy: false,

            exportComponent: false,
            componentData: null,
            exportBtn: false,
            exportBtnIcon: false,
        }
    },

    computed: {
        ...mapGetters(['getIdNumber', 'getPhoneNumber'])
    },

    created() {
        this.fetchProducts()
    },

    methods: {
        fetchProducts() {
            let that = this

            api.products()
                .then((response) => {
                    that.filterLoanProducts(response.data.details)
                })
                .catch(function (error) {
                    that.showError(error);
                });
        },

        filterLoanProducts(products) {
            let that = this

            products.map(function (product) {
                if(product.category == "Loans") {
                    that.products.push(product)
                }
            })  
        },

        calculateLoan() {
            let that = this
            let today = new Date()
            that.date = today.toISOString().slice(0, 10)

            if (that.loanAmount == null || that.selectedPeriod == null) {
                that.showError("Cannot calculate with empty fields.")
            }
            else {
                that.isBusy = true
                that.btnIcon = true
                that.btnDisabled = true

                let data = {}
                data.loanAmount = that.reformatNumber(that.loanAmount)
                data.period = that.selectedPeriod
                data.date = that.date
                data.product = that.selectedProduct

                api.calculator(data)
                    .then((response) => {
                        that.installments = response.data.calcDetails

                        that.isBusy = false
                        that.btnIcon = false
                        that.btnDisabled = false
                    })
                    .catch(function (error) {
                        that.isBusy = false
                        that.btnIcon = false
                        that.btnDisabled = false

                        that.showError(error.response.data.message);
                    });
            }
        },

        formatNumber() {
            if(typeof this.loanAmount ==  "string") {
                this.loanAmount = this.loanAmount.replace(/\,/g,'')
                this.loanAmount = Number(this.loanAmount)
                this.loanAmount = this.loanAmount.toLocaleString()
            }
        },

        reformatNumber(number) {
            return parseInt(number.replace(/,/g, ''));
        },

        changeLoanProduct(product) {
            this.selectedProduct = product.productID
            this.maxLoanTerm = product.maxLoanTerm
            this.selectedPeriod = product.maxLoanTerm
        },

        mountComponent() {
            if(this.installments == null) {
                this.showError("No data available for export")
           }
            else {
                let that = this
                let data = {}
                
                data.date = that.date
                data.product = that.selectedProduct
                data.period = that.selectedPeriod + " Months"
                data.amount = that.loanAmount

                that.componentData = data                
                that.exportBtn = true
                that.exportBtnIcon = true
                that.exportComponent = true
           }
        },

        unmountComponent() {
            let that = this

            that.componentData = null
            
            that.exportBtn = false
            that.exportBtnIcon = false
            that.exportComponent = false
        }
    }
}
</script>

<template>
  <div class="register">
    <TopNav />

    <div id="wrapper">
      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">

          <div class="row">
            <div class="col-lg-6 p-0">
              <img src="../../assets/img/background-12.png" width="100%" />
            </div>

            <div class="custom-register-container-1 col-lg-6 p-4">
              <div class="row custom-register-container-2">
                <div class="col-1">
                  <font-awesome-icon class="custom-register-icon" icon="fa-angle-left" @click="navigateAuthChoose()" />
                </div>
                <div class="col-4">
                  <p class="custom-auth-subtitle m-0">Login</p>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-1"></div>

                <div class="col-lg-8">
                  <small class="text-black">Sign in to your account:</small>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-lg-1"></div>

                <div class="col-lg-8">
                  <label class="text-black m-0">ID Number:</label>
                  <b-form-input type="number" v-model="username" class="custom-auth-input"
                    placeholder="Enter ID number"></b-form-input>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-lg-1"></div>

                <div class="col-lg-8">
                  <label class="text-black m-0">Password:</label>
                  <b-form-input type="password" v-model="password" class="custom-auth-input"
                    placeholder="Enter password"></b-form-input>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-lg-1"></div>

                <div class="col-lg-8">
                  <b-form-select class="custom-auth-select" v-model="selectedQuestion"
                    :options="questions"></b-form-select>
                  <b-form-input class="custom-auth-input" placeholder="Answer security question"
                    v-model="securityAnswer"></b-form-input>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-lg-1"></div>

                <div class="col-lg-8">
                  <div class="row mx-4">
                    <b-button class="custom-auth-signin" @click="signin()" :disabled="btnDisabled">
                      <b-spinner class="custom-spinner" label="Spinning" v-if="btnIcon" small></b-spinner>
                      <span v-if="!btnIcon">CONTINUE</span>
                    </b-button>

                    <div>
                      <small class="text-black">Forgot Password? <span class="custom-text-link"
                          @click="navigateForgotPassword()">Click here</span></small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row custom-register-container-3 p-4 shadow">
            <div class="col-lg-6">
              <p class="text-black m-0">
                Making your life smarter and easier to manage your cash and life everyday, everywhere
              </p>
            </div>
          </div>

          <div class="row custom-auth-container-2 p-4 shadow">
            <div class="col-lg-6">
              <p class="custom-auth-subtitle m-0">
                Amicash helps you enjoy the convenience of banking and access loans at the comfort of your home or at
                go!
              </p>
            </div>
            <div class="col-lg-6 p-2">
              <small class="text-black">
                If you are having issues in registration or login to Online Banking service, please contact our contact
                center or write to us through the following channels;
              </small>

              <div class="row mt-4">
                <div class="col-6 p-0">
                  <small class="custom-auth-heading">Phone Number</small>
                </div>
                <div class="col-6 p-0">
                  <small class="custom-auth-heading">Email</small>
                </div>
              </div>

              <div class="row">
                <div class="col-6 p-0">
                  <small class="text-black">+254 729 333 444</small>
                </div>
                <div class="col-6 p-0">
                  <small class="text-black">customercare@amicacs.co.ke</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import api from "../../apis/api";
import { mapMutations, mapGetters } from 'vuex'
import TopNav from '@/components/TopNav.vue'
import SideNav from '@/components/SideNav.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Login',
  components: {
    SideNav,
    TopNav,
    Footer
  },

  data() {
    return {
      questions: [
        'Which village were you born?',
        'What is your favourite town in Kenya?',
        'Who is your favourite musician?'
      ],
      selectedQuestion: "Which village were you born?",
      securityAnswer: null,
      btnDisabled: false,
      btnIcon: false,
      username: null,
      password: null,
      maxDate: Date.now()
    }
  },

  created() {
    localStorage.clear()
    this.logout()
  },  

  computed: {
   
  },

  methods: {
    ...mapMutations([
      'setNationalID',
      'logout'
    ]),

    navigateAuthChoose() {
      this.$router.push("auth-choose")
    },

    navigateForgotPassword() {
      this.$router.push("forgot-password")
    },

    signin() {
      let that = this

      if (that.username == null || that.password == null || that.securityAnswer == null) {
        that.showError("All inputs are mandatory");
      }
      else {
        that.btnIcon = true
        that.btnDisabled = true

        let data = {
          "username": that.username,
          "password": that.password,
          "securityQuestion": that.selectedQuestion,
          "securityAnswer": that.securityAnswer
        }

        api.login(data)
          .then((response) => {
            that.setNationalID(that.username)
            localStorage.setItem("nationalID", that.username)

            that.$router.push("/otp-verification")
          })
          .catch(function (error) {
            that.btnIcon = false
            that.btnDisabled = false
            that.showError(error.response.data.message);
          });
      }
    },
  },

}
</script>
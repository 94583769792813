<template>
    <div class="register">
      <TopNav />
  
      <div id="wrapper">
        <div id="content-wrapper" class="d-flex flex-column">
          <div id="content">
  
            <div class="row">
              <div class="col-lg-6 p-0">
                <img src="../../assets/img/background-12.png" width="100%" />
              </div>
  
              <div class="custom-register-container-1 col-lg-6 p-4">
                <div class="row custom-register-container-2">
                  <div class="col-1">
                    <font-awesome-icon class="custom-register-icon" icon="fa-angle-left" @click="navigateAuthChoose()" />
                  </div>
                  <div class="col-4">
                    <p class="custom-auth-subtitle m-0">Change Password</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-1"></div>
  
                  <div class="col-lg-6">
                    <small class="text-black">Change password to your account:</small>
                  </div>
                </div>
  
                <div class="row mt-4">
                  <div class="col-lg-1"></div>
  
                  <div class="col-lg-6">
                    <label class="text-black m-0">ID Number:</label>
                    <b-form-input type="number" v-model="username" class="custom-auth-input"
                      placeholder="Enter ID number"></b-form-input>
                  </div>
                </div>
  
                <div class="row mt-4">
                  <div class="col-lg-1"></div>
  
                  <div class="col-lg-6">
                    <label class="text-black m-0">Password:</label>
                    <b-form-input type="password" v-model="password" class="custom-auth-input"
                      placeholder="Enter password"></b-form-input>
                  </div>
                </div>
  
                <div class="row mt-4">
                  <div class="col-lg-1"></div>
  
                  <div class="col-lg-6">
                    <div class="row mx-4">
                      <b-button class="custom-auth-signin" @click="changePassword()" :disabled="btnDisabled">
                        <b-spinner class="custom-spinner" label="Spinning" v-if="btnIcon" small></b-spinner>
                        <span v-if="!btnIcon">CONTINUE</span>
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <div class="row custom-register-container-3 p-4 shadow">
              <div class="col-lg-6">
                <p class="text-black m-0">
                  Making your life smarter and easier to manage your cash and life everyday, everywhere
                </p>
              </div>
            </div>
  
            <div class="row custom-auth-container-2 p-4 shadow">
              <div class="col-lg-6">
                <p class="custom-auth-subtitle m-0">
                  Amicash helps you enjoy the convenience of banking and access loans at the comfort of your home or at
                  go!
                </p>
              </div>
              <div class="col-lg-6 p-2">
                <small class="text-black">
                  If you are having issues in registration or login to Online Banking service, please contact our contact
                  center or write to us through the following channels;
                </small>
  
                <div class="row mt-4">
                  <div class="col-6 p-0">
                    <small class="custom-auth-heading">Phone Number</small>
                  </div>
                  <div class="col-6 p-0">
                    <small class="custom-auth-heading">Email</small>
                  </div>
                </div>
  
                <div class="row">
                  <div class="col-6 p-0">
                    <small class="text-black">+254 729 333 444</small>
                  </div>
                  <div class="col-6 p-0">
                    <small class="text-black">customercare@amicacs.co.ke</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <Footer />
  
    </div>
  </template>
  
  <script>
  import { mapMutations, mapGetters } from 'vuex'
  import api from "../../apis/api";
  import TopNav from '@/components/TopNav.vue'
  import SideNav from '@/components/SideNav.vue'
  import Footer from '@/components/Footer.vue'
  
  export default {
    name: 'Login',
    components: {
      SideNav,
      TopNav,
      Footer
    },
  
    data() {
      return {
        questions: [
          'Which village were you born?',
          'What is your favourite town in Kenyan?',
          'Who is your favourite musician?'
        ],
        question: "Which village were you born?",
        btnDisabled: false,
        btnIcon: false,
        username: null,
        password: null,
        maxDate: Date.now()
      }
    },
    
    computed: {
      //
    },
  
    methods: {
      ...mapMutations([
        'setUserDetails',
        'setClientAccounts'
      ]),
  
      navigateAuthChoose() {
        this.$router.push("auth-choose")
      },
  
      changePassword() {
        let that = this
        that.btnIcon = true
        that.btnDisabled = true
  
        let data = {
          "username": this.username,
          "password": this.password,
        }
  
        api.passwordChange(data)
          .then((response) => {
            that.showSuccess("Password changed successfully. Login to continue")
            that.$router.push("/login")
          })
          .catch(function (error) {
            that.btnIcon = false
            that.btnDisabled = false
            that.showError(error.response.data.message);
          });
      },
  
      fetchUserDetails() {
        let that = this
  
        api.userDetails(that.username)
          .then((response) => {
            that.setUserDetails(response.data[0])
            that.fetchClientDetails()
          })
          .catch(function (error) {
            that.btnIcon = false
            that.btnDisabled = false
            that.showError(error.response.data.message);
          });
      },
  
      fetchClientDetails() {
        let that = this
  
        api.clientDetails()
          .then((response) => {
            that.btnIcon = false
            that.btnDisabled = false
  
            localStorage.setItem("clientAccounts", JSON.stringify(response.data.details))
            that.setClientAccounts(response.data.details)
            that.$router.push("account/balance")
          })
          .catch(function (error) {
            that.btnIcon = false
            that.btnDisabled = false
            that.showError(error.response.data.message);
          });
      }
    },
  
  }
  </script>
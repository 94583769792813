import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { BootstrapVue } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faUser, faHouse, faSackDollar, faLock, faRefresh, faFile, faFilter, 
  faHeadset, faSignOut, faAngleLeft, faBars, faTimes, faInfoCircle, faPiggyBank, faArrowRight, 
  faArrowLeft, faQuestionCircle, faPhone, faEnvelope, faAngleRight, faAngleDown} from '@fortawesome/free-solid-svg-icons'
  
import { faFacebook, faTwitter, faYoutube, faInstagram, faWhatsapp, faLinkedin, faXTwitter, faTiktok } from '@fortawesome/free-brands-svg-icons'

import Toasted from 'vue-toasted';
import toast from './mixins/toast';

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

export const EventBus = new Vue();

import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

ChartJS.register(ArcElement, Tooltip, Legend)

Vue.config.productionTip = false

Vue.use(Toasted)

Vue.mixin(toast)

Vue.use(BootstrapVue)

library.add(faUser, faHouse, faSackDollar, faLock, faRefresh, faFile, faFilter, 
  faHeadset, faSignOut, faAngleLeft, faFacebook, faTwitter, faArrowRight, faArrowLeft,
  faYoutube, faInstagram, faWhatsapp, faLinkedin, faBars, faTimes, faInfoCircle, 
  faPiggyBank, faQuestionCircle, faPhone, faEnvelope, faAngleRight, faAngleDown, faXTwitter, faTiktok)
  
Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
